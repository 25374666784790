import { destroy, get, patch, post } from './index'
import moment from 'moment'

export const Inventory = {
	indexInventory: ({
		page = 0,
		searchTerm,
		locationId,
		updatedFrom,
		updatedTo,
		minQuantity,
		maxQuantity
	} = {}) =>
		get('/inventory', {
			params: {
				page,
				searchTerm,
				locationId,
				updatedFrom,
				updatedTo,
				minQuantity,
				maxQuantity
			}
		}),
	singleWarehouse: (id) => get(`/inventory/warehouses/${id}`),
	fetchSalesOrderInventory: (id) => get(`/inventory/sales-orders/${id}`),
	bulkUpdateInventory: ({
		inventory = []
	} = {}) =>
		post('/inventory', {
			inventory
		}),
	indexInventoryProducts: ({
		page = 0,
		searchTerm,
		locationId,
		updatedFrom,
		updatedTo,
		createdFrom,
		createdTo,
		serialNumber,
		palletId,
		packCode,
		sku,
		isInStock,
		isOrdered,
		distinctFieldName,
		skus,
		blocked
	} = {}) =>
		get('/inventory/inventory-items', {
			params: {
				page,
				searchTerm,
				locationId,
				updatedFrom,
				updatedTo,
				isInStock,
				distinctFieldName,
				createdFrom,
				createdTo,
				serialNumber,
				palletId,
				packCode,
				isOrdered,
				sku,
				skus,
				blocked
			}
		}),
	indexInventoryOnHandStock: ({
		page = 0,
		searchTerm,
		updatedFrom,
		updatedTo,
		createdFrom,
		createdTo,
		serialNumber,
		palletId,
		packCode,
		sku,
		skus,
		shifts,
		packCodes,
		machineCodes,
		shelfLives,
		facilities,
		sortInTransitQuantity,
		sortOnHandStockQuantity
	} = {}) =>
		get('/inventory/on-hand-stock', {
			params: {
				page,
				searchTerm,
				updatedFrom,
				updatedTo,
				createdFrom,
				createdTo,
				serialNumber,
				palletId,
				packCode,
				sku,
				skus,
				shifts,
				packCodes,
				machineCodes,
				shelfLives,
				facilities,
				sortInTransitQuantity,
				sortOnHandStockQuantity
			}
		}),
	indexOnHandStockFilterOptions: ({
		page = 0,
		searchTerm,
		distinctFieldName,
		shelfLife
	} = {}) =>
		get('/inventory/on-hand-stock/filter-options', {
			params: {
				page,
				searchTerm,
				distinctFieldName,
				shelfLife
			}
		}),
	indexOrderMovementFilterOptions: ({
		page = 0,
		searchTerm,
		distinctFieldName,
		shelfLife,
		sku,
		destinationLocation,
		sourceLocation,
		orderNumber
	} = {}) =>
		get('/inventory/order-movement/filter-options', {
			params: {
				page,
				searchTerm,
				distinctFieldName,
				shelfLife,
				sourceLocation,
				destinationLocation,
				sku,
				orderNumber
			}
		}),
	indexInventoryOrderMovement: ({
		page = 0,
		createdFrom,
		createdTo,
		skus,
		shifts,
		packCodes,
		machineCodes,
		shelfLives,
		dropOffLocationIds,
		pickUpLocationIds,
		orderNumbers,
		isOrderDelivered,
		orderTypes
	} = {}) =>
		get('/inventory/order-movement', {
			params: {
				page,
				createdFrom,
				createdTo,
				skus,
				shifts,
				packCodes,
				machineCodes,
				shelfLives,
				dropOffLocationIds,
				pickUpLocationIds,
				orderNumbers,
				isOrderDelivered,
				orderTypes
			}
		}),
	indexInventoryScanAdherence: ({
		orderDateStart,
		orderDateEnd,
		orderDates,
		isStockTransfer,
		pickUpLocationIds,
		dropOffLocationIds,
		orderIds
	} = {}) =>
		get('/inventory/scan-adherence', {
			params: {
				orderDateStart,
				orderDateEnd,
				orderDates,
				isStockTransfer,
				pickUpLocationIds,
				dropOffLocationIds,
				orderIds
			}
		}),
	downloadOnHandStockCsv: async (params) => {
		const response = await get('/inventory/on-hand-stock/csv', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `on_hand_stock_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	downloadOnHandStockXlsx: async (params) => {
		const response = await get('/inventory/on-hand-stock/xlsx', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `on_hand_stock_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
		link.click()
		link.remove()
	},
	downloadOrderMovementCsv: async (params) => {
		const response = await get('/inventory/order-movement/csv', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `order_movement_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	downloadOrderMovementXlsx: async (params) => {
		const response = await get('/inventory/order-movement/xlsx', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `order_movement_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
		link.click()
		link.remove()
	},
	downloadStockMonitoringCsv: async (params) => {
		const response = await get('/inventory/stock-monitoring/csv', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `stock_monitoring_by_${params.bySku ?
			'sku' : 'location'}_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	downloadStockMonitoringXlsx: async (params) => {
		const response = await get('/inventory/stock-monitoring/xlsx', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `stock_monitoring_by_${params.bySku ?
			'sku' : 'location'}_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
		link.click()
		link.remove()
	},
	downloadScanAdherenceXlsx: async (params) => {
		const response = await get('/inventory/scan-adherence/xlsx', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `scan_adherence_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
		link.click()
		link.remove()
	},
	downloadInventoryItemsCsv: async (params) => {
		const response = await get('/inventory/inventory-items/csv', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `inventory_${params.isInStock ? 'stock' : params.blocked ? 'blocked' : 'unpalleted'}_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	fetchReferenceNumber: (workCenter) => get('/inventory/inventory-items/reference-number', {
		params: {
			machineCode: workCenter
		}
	}),
	takeReferenceNumber: ({
		machineCode,
		count
	}) => post('/inventory/inventory-items/reference-number', {
		machineCode,
		count
	}),
	extendShelfLife: ({
		remark,
		shelfLife,
		inventoryProductItemIds
	}) => patch('/inventory/inventory-items/shelf-life', {
		remark,
		shelfLife,
		inventoryProductItemIds
	}),
	blockInventoryProducts: ({
		blocked,
		serialNumbers,
		remark
	}) => patch('/inventory/inventory-items/blocked', {
		blocked,
		serialNumbers,
		remark
	}),
	createInventoryProduct: (data) =>
		post('/inventory/inventory-items', data),
	fetchOrderedInventoryProductItems: (id, { page = 0, searchTerm } = {}) =>
		get(`/inventory/products/${id}/inventory-items`, {
			params: {
				page,
				searchTerm
			}
		}),
	fetchInventoryStockMonitoring: ({
		page = 0,
		bySku,
		byLocation,
		skus,
		facilities,
		shelfLifeRangeKey,
		shelfLives
	} = {}) =>
		get('/inventory/stock-monitoring', {
			params: {
				page,
				byLocation,
				bySku,
				skus,
				facilities,
				shelfLives,
				shelfLifeRangeKey
			}
		}),
	deleteInventoryProductItem: serialNumber =>
		destroy(`/inventory/inventory-items/${serialNumber}`),
	updateInventoryProductItem: (serialNumber, { status }) =>
		patch(`/inventory/inventory-items/${serialNumber}`, { status }),
	fetchProductTotals: () => get('/inventory/products'),
	fetchProductLocations: (productId) => get(`/inventory/products/${productId}/locations`),
	fetchProductsAtLocation: (locationId) => get(`/inventory/products/locations/${locationId}`)
}
