import { get, post, destroy, patch } from './index'

export const Products = {
	index: ({
		searchTerm,
		page = 0,
		category,
		subCategory1,
		subCategory2,
		subCategory3,
		subCategory4,
		subCategory5,
		inventory
	} = {}) =>
		get('/products', {
			params: {
				searchTerm,
				page,
				category,
				subCategory1,
				subCategory2,
				subCategory3,
				subCategory4,
				subCategory5,
				inventory
			}
		}),
	single: (id, {
		inventory,
		packages
	} = {}) =>
		get(`/products/${id}`, {
			params: {
				inventory,
				packages
			}
		}),
	create: (params) =>
		post('/products', params),
	update: (id, params) =>
		patch(`/products/${id}`, params),
	getMetrics: () => get('/products/metrics'),
	remove: (id) =>
		destroy(`/products/${id}`),
	fetchCategories: (industry) =>
		get('/products/categories', {
			params: {
				industry
			}
		}),
	fetchSalesOrderItems: (id, { page = 0 } = {}) =>
		get(`/products/${id}/sales-orders`, {
			params: {
				page
			}
		}),
	fetchPurchaseOrderItems: (id, { page = 0 } = {}) =>
		get(`/products/${id}/purchase-orders`, {
			params: {
				page
			}
		}),
	bulkUpdatePrices: async ({
		products = []
	} = {}) =>
		patch('/products/prices', {
			products
		}),
	fetchSpecifications: ({ type, isDefault }) =>
		get('/products/specifications', {
			params: {
				type,
				isDefault
			}
		}),
	createSpecification: (params) =>
		post('/products/specifications', params)
}
